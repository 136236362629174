import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jordan-tpz/Workspace/galidog-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "porcupine"
    }}>{`Porcupine`}</h1>
    <h2 {...{
      "id": "préambule"
    }}>{`Préambule`}</h2>
    <p>{`Un système de `}<strong parentName="p">{`Hotword`}</strong>{` est mise en place sur l'application mobile. `}</p>
    <p>{`Concrètement, voici le principe : lorsqu'un mot clé est prononcé « oralement » par l'utilisateur, l'application Galidog, alors installée sur le téléphone de l'utilisateur, va intercepter ce mot clé et attendre que l'utilisateur prononce une action connue par le système pour enclencher un comportement ciblé.`}</p>
    <p>{`Cette pratique est similaire à ce qui est maintenant connu du grand public, à savoir : `}<strong parentName="p">{`les assistants vocaux`}</strong>{`. Les plus connus restant `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Amazon_Alexa"
      }}>{`Alexa (by Amazon)`}</a>{` ou encore celui du géant Google via son assistant `}<a parentName="p" {...{
        "href": "https://fr.wikipedia.org/wiki/Assistant_Google"
      }}>{`Ok Google`}</a>{`.`}</p>
    <p>{`Dans le cadre de l'application Galidog, l'équipe de `}<a parentName="p" {...{
        "href": "https://www.troispointzero.fr/"
      }}>{`TroisPointZéro`}</a>{` a opté pour l'utilisation d'une librairie permettant de mettre un place un « écouteur » : `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/@picovoice/porcupine-react-native"
      }}>{`porcupine-react-native`}</a>{`.
Bien qu'il en existe plusieurs sur le marché, c'est l'une des seules nous permettant de mettre en place ce procédé sur une application react-native.`}</p>
    <h2 {...{
      "id": "fonctionnement-technique"
    }}>{`Fonctionnement technique`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Les actions suivantes devront être réalisées depuis le projet `}<a parentName="p" {...{
          "href": "https://gitlab.tpzdev.fr/projets/clients/galidog/galidog-v2/galidog-v2-frontend"
        }}>{`galidog-frontend`}</a>{` !`}</p>
    </blockquote>
    <p>{`Il est possible, soit :`}</p>
    <ul>
      <li parentName="ul">{`d'utiliser les hotwords par défaut qui sont proposés : « alexa », « porcupine », « picovoice », etc. Vous devrez alors exécuter la ligne de commande suivante :`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ sh scripts/generate-config.sh ENVIRONMENT alexa|porcupine|picovoice|... > src/Config/index.js
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`où `}<strong parentName="p">{`ENVIRONMENT`}</strong>{` peut être `}<inlineCode parentName="p">{`dev|staging|production`}</inlineCode></p>
    </blockquote>
    <ul>
      <li parentName="ul">{`d'utiliser son propre wake word model (au format .ppn) en le créant sur `}<a parentName="li" {...{
          "href": "https://picovoice.ai/console/"
        }}>{`la console de Picovoice`}</a>{`. Vous devrez alors réaliser les étapes suivantes :`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Attention ! Si vous utilisez votre propre « wake word model », celui-ci aura une durée de vie de maximum 1 mois (si vous conservez l'offre gratuite (non commerciale)).
Passé ce délai, il faudra (re)générer un « wake word model » ; puis le (re)télécharger dans les sources de l'application ; ce qui nécessitera un rebuild de l'APK.`}</p>
    </blockquote>
    <ol>
      <li parentName="ol">{`déplacez le fichier .ppn précédemment téléchargé dans le répertoire `}<inlineCode parentName="li">{`src/Assets/Porcupine/nom_hotword.ppn`}</inlineCode></li>
      <li parentName="ol">{`modifiez le fichier `}<inlineCode parentName="li">{`scripts/generate-porcupine-resources.js`}</inlineCode>{` en remplaçant le nom du fichier .ppn existant par `}<inlineCode parentName="li">{`nom_hotword.ppn`}</inlineCode></li>
      <li parentName="ol">{`exécutez les lignes de commande suivantes depuis la racine du projet mobile :`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ npm run generate-porcupine-resources
$ sh scripts/generate-config.sh ENVIRONMENT nom_hotword.ppn > src/Config/index.js
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`où `}<strong parentName="p">{`ENVIRONMENT`}</strong>{` peut être `}<inlineCode parentName="p">{`dev|staging|production`}</inlineCode></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      